import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
//import Stats from 'three/examples/jsm/libs/stats.module.js';
//import Stats from 'stats.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import gsap from 'gsap'
import * as dat from 'lil-gui'

//import Vue from 'vue'
import Vue from 'vue/dist/vue.min.js'
import axios from 'axios/dist/axios.min.js'
import SwipeListener from 'swipe-listener';

//PhotoSwipeLightbox
import './photoswipe/photoswipe.css'
import PhotoSwipeLightbox from './photoswipe/photoswipe-lightbox.esm.js'
import PhotoSwipe from './photoswipe/photoswipe.esm.js';

//Stress
import easyBench from './stress/stress.js'


/*
 * PSWP
 */
Vue.component('pswp', {
 	template: `<div><figure>
 				<a v-bind:href="articolo.images.original[0]" v-bind:data-pswp-width="articolo.images.original[1]" v-bind:data-pswp-height="articolo.images.original[2]" target="_blank">
          <img class="m-auto" v-bind:src="articolo.images.full[0]">
 				</a>
      </figure>
      <div class="splide-info"><h3>{{articolo.autore}}, <em>{{articolo.title}}</em></h3>
      <p v-if="articolo.caption" v-html="articolo.caption"></p>
      <p v-if="articolo.description" v-html="articolo.description"></p>
      </div></div>`,
  props: ['articolo'],
 	mounted: function() {
 		let imgArray = document.querySelectorAll('img')

 		const lightbox = new PhotoSwipeLightbox({
 				gallery: '.pswp-gallery',
 				children: 'a',
 				pswpModule: PhotoSwipe,
 				tapAction: 'zoom'
 			});
 		lightbox.init();
 		// console.log('pswp start');
   }
 });

/*
 * VUE
 */
var app = new Vue({
	el: '#app',
  //router: router,
	data: {
		page: 'home', //intro, credits etc. this.current.settings this.current.settingsIndex
    debug: false,
    mobile: false,
    loading: true,
    overlay3d: false,
    menu: false,
    help: false,
    antialias: false,
    helpTitle: 'Come muoversi negli ambienti 3d',
    imageLoaded: false,
    touch:{
      touchstart:{
        x:0,
        y:0
      },
      touchend:{
        x:0,
        y:0
      }
    },
		current:{
			section: null,
			collection: null,
			item: null,
			info: null,
      settings: null,
      settingsIndex: null,
      curveJson: null,
      model: null,
      swipePageType: 'gallery'
		},
		url:{
			base: 'https://backend.napoleoneelaccademia.it/'
		},
		view: {
			section: 'intro', //1802, 1806,
			collection:{
				id: null,
				name: null,
				description: null,
				elements: []
			},
			item: { //elemento in evidenza
				id: null,
				type: null, //sculpture, picture
				title: null,
				description: null,
				originalMaterial: null
			}
		},
		three:{
      loaders:{
        gltf: null,
        draco: null,
        sceneLoaded: false,
        imgLoaded: false
      },
			parameters: {
				cameraOn: true,
        activeCamera: null,
				raycast: false,
        raycastDisable: null,
				start: [],
				stop: [2.5, 1.5, -2.5],
				curveTime: 0,
				curvePosition: 0,
        curvePositionTrigger:{
          forward: false,
          back: false
        },
				curvePositionNext: null,
				curvePositionSelected: 'f_1',
				curveVelocity: 0.01,
				curvePositionTimer: 0,
				curvePositionDirection: 1,
				cameraMove: {
						start: null,
						end: null,
						ready: false
				}
			},
			scene: {
        main: null,
        camera: false,
        name: null,
				position: null,
        positionLabel: null,
        stepList: [
          {scene:19,
            step:[
            {
              key: 'f_40',
              title: 'I Fasti',
              t: 0.183
            },{
              key: 'f_60',
              title: 'Incoronazione di Napoleone',
              t: 0.285
            },{
              key: 'f_100',
              title: 'Andrea Appiani',
              t: 0.4574
            },{
              key: 'f_120',
              title: 'Il Parnaso',
              t: 0.555
            },{
              key: 'f_160',
              title: 'Giuseppe Bossi',
              t: 0.730
            },{
              key: 'f_180',
              title: 'Canova',
              t: 0.820
            },{
              key: 'f_220',
              title: '',
              t: 1
            }
          ]},
          {
            scene:21,
            step:[
              {
                key: 'f_7',
                title: 'Documenti ufficiali',
                t: 0.095
              },
              {
                key: 'f_10',
                title: 'Al cittadino Panzaldi',
                t: 0.175
              },
              {
                key: 'f_40',
                title: 'La Riconoscenza, Bossi',
                t: 0.420
              },
              {
                key: 'f_60',
                title: 'La Riconoscenza, Alberi',
                t: 0.51
              },
              {
                key: 'f_80',
                title: 'La Riconoscenza, Aspari',
                t: 0.59
              },
              {
                key: 'f_100',
                title: 'La Riconoscenza, Borroni',
                t: 0.755
              },
              {
                key: 'f_120',
                title: 'Videoracconto',
                t: 0.84
              },
              {
                key: 'f_140',
                title: 'Porta del Paradiso',
                t: 0.93
              }
            ]
          },
          {
            scene:23,
            step:[
              {
                key: 'f_20',
                title: 'Un\'immagine per Brera',
                t: 0.045
              },
              {
                key: 'f_40',
                title: 'Pensionati Romani',
                t: 0.123
              },
              {
                key: 'f_50',
                title: 'Giasone che piange sul cadavere del giovane Cizico re dei Dolioni',
                t: 0.175
              },
              {
                key: 'f_80',
                title: 'Tavole rappresentati un edifizio alla maniera degli antichi Musei de’ Pittagorici Italiani',
                t: 0.3
              },
              {
                key: 'f_90',
                title: 'Tempio di Antonino e Faustina',
                t: 0.344
              },
              {
                key: 'f_110',
                title: 'Paesaggio Romano con tramonto',
                t: 0.415
              },
              {
                key: 'f_120',
                title: 'Danae e Perseo bambino salvati dai Corsali all’isola di Serifo',
                t: 0.46
              },
              {
                key: 'f_130',
                title: 'Sepoltura delle ceneri di Temistocle nella terra attica',
                t: 0.5
              },
              {
                key: 'f_140',
                title: 'Ermafrodito',
                t: 0.57
              },
              {
                key: 'f_150',
                title: 'Dioscuri',
                t: 0.625
              },
              {
                key: 'f_160',
                title: 'Galata Morente',
                t: 0.67
              },
              {
                key: 'f_170',
                title: 'Venere accovacciata',
                t: 0.71
              },
              {
                key: 'f_190',
                title: 'Apoteosi di Psiche',
                t: 0.8
              },{
                key: 'f_210',
                title: 'Clemente',
                t: 0.84
              },{
                key: 'f_220',
                title: 'Vespasiano',
                t: 0.925
              }
            ]
          }
        ],
        models: {
          room: null,
          sculptures: null,
          clickArea:[
            {
              name: '2-napoleone',
              file:'1005-IncoronazioneDiNapoleone',
            },{
              name: '6-canova',
              file:'1200-CanovaNapoleone',
            }
          ]
        },
				objectsToTest: [],
				cameraStep: null, //json
				cameraPositionSpline: {
					curve: null,
          targetCurve: null,
					curveLenght: null
				},
				selectedArtwork:{ //da spostare...
					id: null,
					type: null,
					status: null //selected, expanded
				}
			}
		}
	},
	mounted: function() {
    //this.findPage()
    this.hasAntiAlias()
    this.isDebug()
    this.isMobile()
    this.loadSettings()
    this.mainScene()
    //this.findPage()
    this.setSwipeEvents()
    this.loadersSetup()
    this.checkback()
    //this.searchStep()
    //this.warning()
	},
	methods: {
    checkback: function(){
      window.onpopstate =  (()=>{
        this.findPage()
      });
    },
    onImgLoad: function(){
      //console.log('immagine')
      this.imageLoaded = true
    },
    setHash: function(hash){
      var url_ob = new URL(document.URL);
      url_ob.hash = '#'+ hash;
      var new_url = url_ob.href;
    },
    hasAntiAlias: function(){
  		easyBench.load();
  		let benchmark = easyBench.computeProcessingPower();
  		if (benchmark[1] >= 3) {
  			this.antialias = true;
  		}
    },
    findPage: function(){
      if(window.location.hash === '#'){
        this.showSection('home',true)
      }else if(window.location.hash === '#about'){
        this.showSection('about',true)
      }else if(window.location.hash === '#colophon'){
        this.showSection('colophon',true)
      }else{
        for (let sezz in this.current.settings.Home.Sezioni) {
          if(window.location.hash === '#'+ this.current.settings.Home.Sezioni[sezz].Slug ){
            this.introSection(sezz,true)
          }
        }
      }
    },
    isDebug: function(){
      if(window.location.hash === '#debug'){
        this.debug = true;
      }else{
        this.debug = false;
      }
    },
    loadersSetup: function(){

      const loadingManager = new THREE.LoadingManager(
          // Loaded
          () =>
          {
              //console.log('loaded')
              this.three.loaders.sceneLoaded = true
          },
          // Progress
          () =>
          {
              //console.log('progress')
          }
      )

      this.three.loaders.gltf = new GLTFLoader(loadingManager)
      this.three.loaders.draco = new DRACOLoader()
    },
    swipeStart: function(event){
      this.touch.touchstart.x = event.changedTouches[0].screenX;
      this.touch.touchstart.y = event.changedTouches[0].screenY;
    },
    toggleOverlay3d: function(){
      if(this.overlay3d){
        this.overlay3d = false
      }else{
        this.overlay3d = true
      }
    },
    swipeEnd: function(event){
      this.touch.touchend.x = event.changedTouches[0].screenX;
      this.touch.touchend.y = event.changedTouches[0].screenY;
     let coeff = 1
     if( this.current.settingsIndex === 23){
       coeff = 0.5
     }
     this.moveTheCurve( (this.touch.touchstart.y - this.touch.touchend.y)*5*coeff )
    },
    defineCurve: function(){

      let sceneIndex = this.current.settingsIndex
      let mainScene = this.three.scene.main

      const cameraPosition = []
      const targetPosition = []
      const curveJson = this.current.curveJson
      const cameraLocationCoords = curveJson.animation.CameraPath.location
      const cameraTargetCoords = curveJson.animation.CameraTarget.location

      /*debug cube*/
      const cube1 = new THREE.Mesh(
          new THREE.BoxGeometry(0.2,0.2,0.2),
          new THREE.MeshBasicMaterial({ color: 0x0000ff, wireframe: true })
      )
      cube1.position.set(0, 0, 0)

      /* cube for debug */
      for (var k in cameraLocationCoords){
      		cameraPosition.push(
      			new THREE.Vector3(
      				cameraLocationCoords[k][0],
      				cameraLocationCoords[k][1],
      				cameraLocationCoords[k][2]
      			)
      		)
        //DebugCUBE
        // var newCube = cube1.clone();
        // newCube.position.set(cameraLocationCoords[k][0],cameraLocationCoords[k][1],cameraLocationCoords[k][2]);
        // mainScene.add( newCube );
      }

      for (var t in cameraTargetCoords){
      		targetPosition.push(
      			new THREE.Vector3(
      				cameraTargetCoords[t][0],
      				cameraTargetCoords[t][1],
      				(cameraTargetCoords[t][2])
      			)
      		)
        //DebugCUBE
        // var newCube2 = cube1.clone();
        // newCube2.position.set(cameraTargetCoords[t][0],cameraTargetCoords[t][1],cameraTargetCoords[t][2]);
        // mainScene.add( newCube );
      }

      let stepPositions = []

      for (var key in curveJson.animation.CameraPath.location) {
      		const newObject = new Object();
      		newObject.key = key
      		let pointsPosition = new THREE.Vector3(
      			curveJson.animation.CameraPath.location[key][0],
      			curveJson.animation.CameraPath.location[key][1],
      			curveJson.animation.CameraPath.location[key][2]
      		)
      		newObject.position = pointsPosition

      		if(key in curveJson.animation.markers){
      				newObject.name = curveJson.animation.markers[key]
      		}else{
      				newObject.name = 'no name'
      		}
      		newObject.curve = null
      		newObject.lenght = 0
      		stepPositions.push(newObject)
      }

      //forse c'è un modo migliore ma per adesso...
      for (let i = 1; i < stepPositions.length; i++) {
      	let newArray = []
      	for (let k = 0; k <= i; k++) {
      		newArray.push( cameraPosition[k] )
      	}
      	let newArrayCurve = new THREE.CatmullRomCurve3( newArray )
      	newArrayCurve.curveType = 'catmullrom'
        //newArrayCurve.tension = 0.2
      	newArrayCurve.closed = false
      	stepPositions[i].curve = newArrayCurve
      	stepPositions[i].lenght = newArrayCurve.getLength()

        if(this.debug === true && i === 9){
          const ncameraPositionPoints = newArrayCurve.getPoints( 50 );
          const ncameraPositionGeometry = new THREE.BufferGeometry().setFromPoints( ncameraPositionPoints );
          const ncameraPositionMaterial = new THREE.LineBasicMaterial( { color: 0xffff00 } );
          const ncameraPositionSplineObject = new THREE.Line( ncameraPositionGeometry, ncameraPositionMaterial );
          mainScene.add( ncameraPositionSplineObject );
        }
      }

      this.three.scene.cameraStep = stepPositions

      this.three.scene.cameraPositionSpline.curve = new THREE.CatmullRomCurve3( cameraPosition )
      this.three.scene.cameraPositionSpline.curve.curveType = 'catmullrom'
      //this.three.scene.cameraPositionSpline.curve.tension = 0.2
      this.three.scene.cameraPositionSpline.curve.closed = false
      this.three.scene.cameraPositionSpline.curveLenght = this.three.scene.cameraPositionSpline.curve.getLength()

      const cameraPositionPoints = this.three.scene.cameraPositionSpline.curve.getPoints( 50 );

      if(this.debug === true){
        const cameraPositionGeometry = new THREE.BufferGeometry().setFromPoints( cameraPositionPoints );
        const cameraPositionMaterial = new THREE.LineBasicMaterial( { color: 0x1010dd } );
        const cameraPositionSplineObject = new THREE.Line( cameraPositionGeometry, cameraPositionMaterial );
        mainScene.add( cameraPositionSplineObject );
      }

      //trova gli step
      for (var i = 0; i < Object.keys(this.three.scene.cameraStep).length; i++) {

        let searchArray = []   // Array for second curve
        let uPosition = null // Result is null for now

        this.three.scene.cameraStep[i].u = false

        // Loop through curve.points to find our final point
        for(var n = 0; n < this.three.scene.cameraPositionSpline.curve.points.length; n++){

            //console.log('cameraCurve.points',this.three.scene.cameraPositionSpline.curve.points[n])

            searchArray.push(this.three.scene.cameraPositionSpline.curve.points[n]);

            // Exit loop once point has been found
            if( this.three.scene.cameraStep[i].position.equals(this.three.scene.cameraPositionSpline.curve.points[n]) ){

              //posizione t impostata su 'this.three.scene.stepList'
              for(var nn = 0; nn < Object.keys(this.three.scene.stepList).length; nn++){
                if(this.three.scene.stepList[nn].scene === this.current.settingsIndex){
                 for(var s = 0; s < Object.keys(this.three.scene.stepList[nn].step).length; s++){
                  if(this.three.scene.stepList[nn].step[s].key === this.three.scene.cameraStep[i].key){
                    this.three.scene.cameraStep[i].title = this.three.scene.stepList[nn].step[s].title
                    this.three.scene.cameraStep[i].t = this.three.scene.stepList[nn].step[s].t
                   }
                 }
                }
              }

              if(this.three.scene.cameraStep[i].lenght === 0){
                uPosition = 0
              }else{
                uPosition = this.three.scene.cameraStep[i].lenght / this.three.scene.cameraPositionSpline.curve.getLength();
              }

              this.three.scene.cameraStep[i].u = uPosition
            }
        }
      }

      /*TARGET SPLINE*/
      const targetPositionSpline = new THREE.CatmullRomCurve3( targetPosition);
      targetPositionSpline.curveType = 'catmullrom'
      targetPositionSpline.closed = false
      targetPositionSpline.tension = 0.2
      this.three.scene.cameraPositionSpline.targetCurve = targetPositionSpline

      //aggiungi la spline alla scena (solo per debug)
      if(this.debug === true){
        const targetPositionSplineObject = new THREE.Line(
         new THREE.BufferGeometry().setFromPoints( targetPositionSpline.getPoints( 50 ) ),
         new THREE.LineBasicMaterial( { color: 0xff10dd } )
         );
        mainScene.add( targetPositionSplineObject );
      }

    },
    mainIntroSection: function(){
      this.page = 'mainintro'
    },
    introSection: function(sceneID,closeMenu = false){
      this.page = 'intro'
      this.current.settingsIndex = parseInt(sceneID)

      if(closeMenu){
        this.menu = false
      }
      this.three.parameters.raycast = false

      //reset scroll div position:
      let myDiv = document.getElementById('intro-section--page')
      if(myDiv){
        myDiv.scrollTop = 0
      }

      this.startSection()
    },
    showSection: function(section,closeMenu = false){

      this.page = section

      if(section == 'show'){
        this.enableRaycast()
      }else{
        this.disableRaycast()
      }

      if(closeMenu){
        this.menu = false
      }
    },
    disableRaycast: function(){
      this.three.parameters.raycast = false
    },
    enableRaycast: function(){
      this.three.parameters.raycast = true
    },
    startSection: function(){

      this.three.scene.positionLabel = false
      this.three.parameters.curvePositionSelected = false
      this.three.loaders.sceneLoaded = false
      const dracoLoader = this.three.loaders.draco
      const loader = this.three.loaders.gltf
      const objectArray = []
      let mainScene = this.three.scene.main

      let cArray = []

      if(this.current.settingsIndex){
        //delete
        this.three.scene.main.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()
                for(const key in child.material)
                {
                    const value = child.material[key]
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })

        this.three.scene.main.traverse((child) =>
        {
            if(child instanceof THREE.Group && child.name == 'Scene')
            {
                cArray.push(child)
            }
        })
      }

      if(cArray){
        cArray.forEach((item, i) => {
          this.three.scene.main.remove(item)
        });
      }

      this.three.parameters.curvePosition = 0

      dracoLoader.setDecoderPath('draco/')
      dracoLoader.crossOrigin = ''
      loader.setDRACOLoader(dracoLoader)

      //prendo dal json le informazioni per la scena 3d
      let sceneIndex = this.current.settingsIndex
      let sceneUrl = this.current.settings.Home.Sezioni[sceneIndex].Ambiente3d[0].file

      //leggo i dati del percorso e creo una curva
      this.current.curveJson = this.current.settings.Home.Sezioni[sceneIndex].Percorso3d
      this.defineCurve()

      const childMaterialTransparent = new THREE.MeshBasicMaterial({ color: 0xff0000 })
      childMaterialTransparent.opacity = 0
      childMaterialTransparent.transparent = true

      //carico la scena 3D
      loader.load(
        sceneUrl,
      	function ( gltf ) {
      		gltf.scene.position.set(0, 0, 0)
      		mainScene.add( gltf.scene );
      		gltf.scene.traverse((child) =>
              {
      						if(child.name.startsWith("Click")){
      							objectArray.push(child)
      							//this.three.scene.objectsToTest.push(child)
      							child.material = childMaterialTransparent
      						}
              })
      	},
      	function ( xhr ) {
      		//console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' + sceneUrl );
      	},
      	function ( error ) {
          console.log( error, 'An error happened on ' + sceneUrl );
      	}
      );

      //carico i modelli 3d delle statue nella scena
      //se il loro nome è presente nell'elenco del .json e nelle rest API
      if(this.current.curveJson.models){

        this.three.scene.objectsToTest = objectArray
        this.current.settings.Home.Sezioni[sceneIndex].Ambiente3d.forEach((model, i) => {
          if(i != 0){
            this.loadGLTFModel(model,loader)
          }
        });

      }
    },
    loadGLTFModel: function(model,loader){

      const json = this.current.curveJson
      const mainScene = this.three.scene.main
      const gltfURL = model.file
      const slug = model.slug
      const name = slug.replace("-3d", "")

      let location = null
      let rotation = null

      loader.load(
        gltfURL,
        function (gltf ) {
          gltf.scene.position.set(json.models[name].location[0],json.models[name].location[1],json.models[name].location[2] )
          gltf.scene.rotation.set(json.models[name].rotation[0],json.models[name].rotation[1],json.models[name].rotation[2] )
          mainScene.add( gltf.scene )
        },
        function ( xhr ) {
          //console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded '+ gltfURL );
        },
        function ( error ) {
          console.log(error, 'error loading' + gltfURL)
        }
      );

    },
    hideElements: function(){
      this.three.scene.main.traverse((child) =>
      {
        if(child instanceof THREE.Mesh){
          child.visible = false
        }
      })
    },
    disposeAndShow: function(){
      this.three.scene.main.traverse((child) =>
      {
          if(child instanceof THREE.Mesh)
          {
              if(child.visible){

                  child.geometry.dispose()

                  for(const key in child.material)
                  {
                      const value = child.material[key]

                      if(value && typeof value.dispose === 'function')
                      {
                          value.dispose()
                      }
                  }

                //rimuovi la mesh e il gruppo'Scene' che la contiene
                this.three.scene.main.remove( child.parent )

              }else{
                if(child.name != 'cameraTarget'){
                  child.visible = true
                }
              }
          }
        })
    },
    moveCurvePosition: function( forward ) {
      if(forward){
        this.three.parameters.curvePositionTrigger.forward = true
      }else{
        this.three.parameters.curvePositionTrigger.back = true
      }
    },
    moveTheCurve: function(param){

      let coeff = 1
      if( this.current.settingsIndex === 23){
        coeff = 0.2
      }

      if( this.three.parameters.cameraOn && (this.three.parameters.curvePosition + param*0.00005*1) >= 0){
        this.three.parameters.curvePosition += param*coeff*0.00005*1
        this.three.scene.name = null
        this.three.parameters.curvePositionSelected = null

        this.updateLabelPosition()
      }

    },
    stopMoveCurvePosition: function( forward ) {
      this.three.parameters.curvePositionTrigger.forward = false
      this.three.parameters.curvePositionTrigger.back = false
    },
    updateIndex: function(index) {
					this.current.item = index
		},
		lightboxMethod: function(){
			const lightbox = new PhotoSwipeLightbox({
				gallery: '#gallery--no-dynamic-import',
				children: 'a',
				pswpModule: PhotoSwipe
			});
			lightbox.init();
		},
    loadSettings: function (){

			var route = this.url.base + 'wp-json/ml/settings'
			axios.get( route, { timeout: 5000, maxRedirects: 3 } )
			.then(response => {

        this.current.settings = response.data
        if(response.data != null){
          this.findPage()

        }
			})
			.catch(error => {
        console.log( error, route )
        if(!error.status) {
					console.log( 'ERROR. No REST API Founded for SETTINGS', route  )
          this.page = 'error'
          //TODO @luca
          //MAINTENANCE MODE!!!
				}
			})
		},
    helpPage: function (){
      if( this.help === false){
        if( this.current.swipePageType == '3d' && this.page == 'swipe'){
          this.help = 2
        }else if ( this.page == 'show' ) {
          this.help = 1
        }
      }
      this.changeHelpTitle()
      this.disableRaycast()
    },
    closeHelpPage: function (){
      if( this.help ){
        this.help = false
        this.enableRaycast()
      }
    },
    changeHelpTitle: function(){
      if( this.help === 1){
        this.helpTitle = 'Come muoversi negli ambienti 3d'
      }else{
        this.helpTitle = 'Come visualizzare i modelli 3d'
      }
    },
    switchHelpPage: function (){
      if( this.help === 1){
        this.help = 2
      }else{
        this.help = 1
      }
      this.changeHelpTitle()
    },
		loadData: function (scene, controls = null, cameraControls = null){

      var route = this.url.base + 'wp-json/ml/gruppi?gruppo=' + this.current.section

      const loader = this.three.loaders.gltf

      axios.get( route, { timeout: 3500, maxRedirects: 3 } )
			.then(response => {

				this.current.info = response.data
        this.current.model = null
        this.current.swipePageType = 'gallery'
        //check if is 3D
        for (var i = 0; i < this.current.info.opere.length; i++) {

          if( this.current.info.opere[i].hasOwnProperty('file') ){
            if( this.current.info.opere[i].file.endsWith(".gltf") ){
              // si assume che ci sia un solo 3d per API
              this.current.model = this.current.info.opere[i].file
              this.current.swipePageType = '3d'

              loader.load(
                this.current.model,
                function (gltf ) {

                  gltf.scene.position.set(0,0,0)
                  scene.add( gltf.scene )

                  let zDistance = 5

                  if(controls && cameraControls){

                    //default
                    controls.target = new THREE.Vector3(
                      0,
                      1.5,
                      0
                    )
                    cameraControls.position = new THREE.Vector3(
                      0,
                      1.5,
                      7
                    )
                    controls.maxPolarAngle = Math.PI/2;
                    controls.maxAzimuthAngle = 'Infinity'
                    controls.minAzimuthAngle = 'Infinity'


                    if(gltf.scene.children[0].name == '1060-ermafrodito'){
                      controls.target = new THREE.Vector3(
                        0,
                        0.5,
                        0
                      )
                      cameraControls.position = new THREE.Vector3(
                        0,
                        0.5,
                        3
                      )
                        zDistance = 2
                    }else if(gltf.scene.children[0].name == '15-portaparadiso'){
                      controls.target = new THREE.Vector3(
                        0,
                        2.5,
                        0
                      )
                      cameraControls.position = new THREE.Vector3(
                        0,
                        2.5,
                        11
                      )
                      zDistance = 9
                      controls.maxAzimuthAngle = Math.PI/2
                      controls.minAzimuthAngle = -Math.PI/2
                    }else if(gltf.scene.children[0].name == '1005-IncoronazioneNapoleone'){

                      controls.maxAzimuthAngle = Math.PI/2
                      controls.minAzimuthAngle = -Math.PI/2
                    }else if(gltf.scene.children[0].name == '1066-venere'){

                      controls.target = new THREE.Vector3(
                        0,
                        0.5,
                        0
                      )
                      cameraControls.position = new THREE.Vector3(
                        0,
                        0.5,
                        3
                      )
                      zDistance = 2
                    }else if( gltf.scene.children[0].name == '26-galata-HP'){
                      controls.target = new THREE.Vector3(
                        0,
                        0.5,
                        0
                      )
                      cameraControls.position = new THREE.Vector3(
                        0,
                        0.5,
                        2
                      )
                    }
                    gsap.to(cameraControls.position, {duration:1,delay:0, z:zDistance} )
                  }
                },
                function ( xhr ) {
                  //console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                },
                function ( error ) {
                  console.log( error, 'An error happened loading model' );
                }
              )
              this.three.parameters.cameraOn = false
              //reset overlay
              this.overlay3d = false
            }else if( this.current.info.opere[i].file.endsWith(".mp4") ){
              this.current.model = this.current.info.opere[i].file
              this.current.swipePageType = 'video'
            }
          }
        }
			})
			.catch(error => {
        console.log(error)
				if(!error.status) {
					console.log( 'ERROR. No REST API Founded for this SECTION ', route, error  )
				}
			})
		},
		prevPage: function(){
			if(this.page == 'swipe'){
        this.page = 'show'
        this.current.info = null
        this.current.item = null
        this.three.scene.name = null
        this.enableRaycast()
        this.disposeAndShow()
        if(this.three.parameters.cameraOn === false){
          this.three.parameters.cameraOn = true
        }
			}
		},
		swipePage: function(){
				this.page = 'swipe'
		},
		setNextFrame: function(){
			for (let i = 0; i < this.current.curveJson.animation.CameraPath.location.length; i++) {
			//console.log('funziona',this.three.scene.points[i][3])

				// if(this.three.scene.points[i][3] === this.three.scene.name){
        //
				// 	this.three.parameters.nextCurvePosition = this.searchCurvePosition(this.three.scene.cameraPositionSpline, new THREE.Vector3(this.three.scene.points[i][0][0],this.three.scene.points[i][0][1],this.three.scene.points[i][0][2]) )
				// 	console.log(this.three.parameters.nextCurvePosition)
				// }

			}
		},
    udpateCurvePositionPercent: function (val){

      let curveStart = (val - 0.2)

      if( this.three.parameters.curvePosition > (val - 0.2) ){
        curveStart = this.three.parameters.curvePosition
      }

      //per il tasto di fine corsa
      this.three.parameters.curvePositionNext = val + 0.2
      if(curveStart > this.three.parameters.curvePosition){
        this.three.parameters.curvePosition = curveStart
      }

			this.three.parameters.curvePositionSelected = 'end'
    },
		updateCurvePosition: function (step){

       let deltaPosition = step.t - this.three.parameters.curvePosition

       const deltaPositionSize = 0.2
       const deltaMove = 0.02

       if(deltaPosition > deltaPositionSize){
         if(deltaPosition > 0){
           this.three.parameters.curvePosition = (step.t - deltaMove)
         }else{
           this.three.parameters.curvePosition = (step.t + deltaMove)
         }
       }

       this.three.parameters.curvePositionNext = step.t
       this.three.parameters.curvePositionSelected = step.key
		},
		/*updateCameraPosition: function (step){

			let curvePositionNorm = step.lenght / this.three.scene.cameraPositionSpline.curveLenght

			let curvePositionNormUT = this.three.scene.cameraPositionSpline.curve.getUtoTmapping( curvePositionNorm );
			if(curvePositionNorm>1){
				curvePositionNormUT = 1
			}else if(curvePositionNorm < 0 ){
				curvePositionNormUT = 0.2
			}

			this.three.parameters.curvePositionNext = curvePositionNormUT
			this.three.parameters.curvePositionSelected = step.key
			this.three.parameters.cameraMove.end = step.position

			let getPoint = this.three.scene.cameraPositionSpline.curve.getPoint( this.three.parameters.curvePosition )

			this.three.parameters.cameraMove.start = getPoint
			this.three.parameters.cameraMove.ready = true
		},*/
    toggleMenu: function(){
      if(this.menu === false){
        this.menu = true
      }else{
        this.menu = false
      }
    },
    isMobile: function (){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.mobile = true
      }else{
        this.mobile = false
      }
    },
		/*searchCurvePosition: function (curve, searchPoint){
			//https://stackoverflow.com/questions/50257825/get-position-of-point-on-curve

			var searchArray = [];   // Array for second curve
			var uPosition = null; // Result is null for now
			var curve2 = null;
			// Loop through curve.points to find our final point
			for(var i = 0; i < curve.points.length; i++){
					searchArray.push(curve.points[i]);

					// Exit loop once point has been found
					if(searchPoint.equals(curve.points[i])){
							// Create shorter curve that stops at desired point
							curve2 = new THREE.CatmullRomCurve3(searchArray);

							// Result is short length / original length
							uPosition = curve.getUtoTmapping( curve2.getLength() / curve.getLength() );
							// console.log( 'uPosition',curve2.getLength(),curve.getLength() );
							break;
					}
			}

			// Result is null if position not found
			//console.log('uPosition',uPosition, curve.getUtoTmapping(uPosition) );
			return uPosition
		},*/
    // handleGesture: function () {
    //     if (touchendX <= touchstartX) {
    //         console.log('Swiped left');
    //     }
    //
    //     if (touchendX >= touchstartX) {
    //         console.log('Swiped right');
    //     }
    //
    //     if (touchendY <= touchstartY) {
    //         console.log('Swiped up');
    //         //this.moveCurvePosition(true)
    //     }
    //
    //     if (touchendY >= touchstartY) {
    //         console.log('Swiped down');
    //         //this.moveCurvePosition(false)
    //     }
    //
    //     if (touchendY === touchstartY) {
    //         console.log('Tap');
    //     }
    // },
    swip: function (e){

      if( this.three.parameters.cameraOn && this.mobile){
        this.moveTheCurve( (e.detail.y[1] - e.detail.y[0])*0.2 )
      }

    },
    setSwipeEvents: function (){
      var webglarea = document.getElementById( 'webglarea' );
      var listener = SwipeListener(webglarea);
    },
    warning: function (){
      window.onbeforeunload = function() {
        return "Vuoi uscire dal sito?";
       };
    },
    closest: function(counts, goal){
      let closest = counts.reduce(
        function(prev, curr) {
          return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
        }
      );
    },
    /* betweenvalues: function(val){
    //   if(val !== undefined){
    //     if(val.t > this.three.parameters.curvePosition - 0.1 || val.t < this.three.parameters.curvePosition + 0.1){
    //       return val
    //     }
    //   }
    // },*/
    updateLabelPosition: function(){

      let result = false

      for(var nn = 0; nn < Object.keys(this.three.scene.stepList).length; nn++){

        if(this.three.scene.stepList[nn].scene === this.current.settingsIndex){
          let offset = 0.02
          if(this.current.settingsIndex === 23){
            offset = 0.02
          }
          result = this.three.scene.stepList[nn].step.find(x => x.t >= (this.three.parameters.curvePosition - offset) )
          if(result !== undefined){
            if("title" in result){
              if( this.three.parameters.curvePosition >= (result.t - offset) && this.three.parameters.curvePosition < (result.t + offset) ){
                this.three.scene.positionLabel = result.title
              }else{
                this.three.scene.positionLabel = false
              }
            }
            if("key" in result){
              this.three.parameters.curvePositionSelected = result.key
            }
          }
        }
      }

    },
		mainScene: function (){

   //  let stats
   //  const appcontainer = document.getElementById( 'app' );
   //
   //  stats = new Stats();

   //  appcontainer.appendChild( stats.dom );


// Debug
const gui = new dat.GUI()

if(this.debug === true){
  gui.show()
}else{
  gui.hide()
}


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
this.three.scene.main = scene

// Axes
// const axesHelper = new THREE.AxesHelper( 5 );
// scene.add( axesHelper );

/**
 * Fog
 */
const fog = new THREE.Fog('#b5b7bb', 55, 120)
scene.fog = fog


const childMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 })
const childMaterialHover = new THREE.MeshBasicMaterial({ color: 0x0000ff })
childMaterialHover.opacity = 0.1
childMaterialHover.transparent = true

/*
 * disable raycast
 */



//if(this.three.parameters.cameraOn){
//


// if(this.three.parameters.raycastDisable){
//   this.three.parameters.raycastDisable.forEach(item => {
//     console.log('query',item)
//     item.addEventListener('mouseenter', event => {
//       this.three.parameters.raycast = false
//       console.log('mouseenter')
//     })
//     item.addEventListener('mouseleave', event => {
//       this.three.parameters.raycast = true
//     })
//   })
// }




//}

/*
 * SET CAMERA POSITIONS
 */

const cameraGroup = new THREE.Group();
scene.add(cameraGroup)

const targetGroup = new THREE.Group();
scene.add(targetGroup)

/** **/

// const cube1 = new THREE.Mesh(
//     new THREE.BoxGeometry(0.4,0.4,0.4),
//     new THREE.MeshBasicMaterial({ color: 0x0000ff, wireframe: true })
// )
// cube1.position.set(0, 0, 0)



  //mostra il target della camera
  const cube3 = new THREE.Mesh(
      new THREE.BoxGeometry(0.4,0.4,0.4),
      new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true })
  )
  cube3.visible = false
  cube3.name = 'cameraTarget'
  let cube3props = {
  	x:0,
  	y:0,
  	z:0
  }
  cube3.position.set(cube3props.x, cube3props.y, cube3props.z)
  targetGroup.add( cube3 );

  if(this.debug === true){
    cube3.visible = true
  }

/**
 * Light
 */

// Ambient light
const ambientLightValues = {
	intensity: 1,
	color: new THREE.Color( 0xffffff )
}

gui.add(ambientLightValues, 'intensity').min(0).max(5).step(0.5).onChange(()=>
	{
		ambientLight.intensity = ambientLightValues.intensity
	})

/* gui
	.addColor(ambientLightValues, 'color')
	.onChange(()=>
	{
		ambientLight.color = ambientLightValues.color
	}) */

const ambientLight = new THREE.AmbientLight(ambientLightValues.color, ambientLightValues.intensity)
scene.add(ambientLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

let focal = null

if(window.innerWidth > window.innerHeight){
 focal = 40
}else{
 focal = 75
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

	  if(window.innerWidth > window.innerHeight){
	  	focal = 40
	  }else{
	  	focal = 75
	  }

    // Update camera
    this.three.scene.camera.aspect = sizes.width / sizes.height
		this.three.scene.camera.fov = focal
    this.three.scene.camera.updateProjectionMatrix()

    //update camera "cameraControls" x modelli 3d
    cameraControls.aspect = sizes.width / sizes.height
    cameraControls.fov = focal
    cameraControls.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */


this.three.scene.camera = new THREE.PerspectiveCamera(focal, sizes.width / sizes.height, 0.1, 100)
this.three.scene.camera.position.x = 0
this.three.scene.camera.position.y = 0
this.three.scene.camera.position.z = 0

scene.add(this.three.scene.camera)

const cameraControls = new THREE.PerspectiveCamera(focal, sizes.width / sizes.height, 0.1, 100)
cameraControls.position.x = 55
cameraControls.position.y = 1
cameraControls.position.z = 0
scene.add(cameraControls)

//for debug
// const helper = new THREE.CameraHelper( this.three.scene.camera );
// scene.add( helper );

// Controls app
const app = document.getElementById('app')
let controls = new OrbitControls(cameraControls, canvas)
//


//let controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enableRotate = true

//define STEP POSITIONS in curve [0,1] range
// for (var i = 0; i < this.three.scene.cameraStep.length; i++) {
//   console.log('camera-step', this.three.scene.cameraStep[i])
// }










/* let controls = new FirstPersonControls( camera, canvas );
controls.movementSpeed = 150;
controls.lookSpeed = 0.1; */


/* controls.maxPolarAngle = Math.PI/1.75 //Math.PI

controls.minPolarAngle = Math.PI/2.5 //0 */

//controls.enableRotate = false

/* controls.mouseButtons = {
	LEFT: THREE.MOUSE.PAN, //THREE.MOUSE.ROTATE
	MIDDLE: THREE.MOUSE.PAN,
	RIGHT: THREE.MOUSE.PAN
} */

/* controls.touches = {
	ONE: THREE.TOUCH.ROTATE,
	TWO: THREE.TOUCH.DOLLY_PAN
} */

/*
const azimuthAngle = {
	"min": Math.PI/2 - 0.1, //-Math.PI,
	"max": Math.PI + 0.1 //Math.PI
}

controls.minAzimuthAngle = azimuthAngle.min
controls.maxAzimuthAngle = azimuthAngle.max

gui.add(azimuthAngle, 'min').min(0).max(2*Math.PI).step(Math.PI/8).onChange(()=>
{
	controls.minAzimuthAngle = azimuthAngle.min
})

gui.add(azimuthAngle, 'max').min(0).max(2*Math.PI).step(Math.PI/8).onChange(()=>
{
	controls.maxAzimuthAngle = azimuthAngle.max
})

//per testare le posizioni!
const debugObject = {
		ca2: () => { console.log('ClickArea-2_Napoleone',cortileJson) }, //40.0
		ca6: () => { console.log('ClickArea-6_Canova') }
}
gui.add(debugObject, 'ca2')
gui.add(debugObject, 'ca6')
gui.add( this.three.scene, 'name', { 'ClickArea-2_Napoleone', quadro2: 8, quadro3: 9, statua1: 6 } )
.onChange(()=>
	{
		console.log('name', this.three.scene.name)
		this.setNextFrame()
	}) */

gui
	.add(this.three.parameters, 'cameraOn')
	.onChange(()=>
	{
		if(this.three.parameters.cameraOn){
			controls = new OrbitControls(this.three.scene.camera, canvas)
		}else{
			controls = new OrbitControls(cameraControls, canvas)
		}
	})

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: this.antialias
})

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

renderer.setClearColor('#b5b7bb')

renderer.toneMapping = THREE.ReinhardToneMapping;
renderer.toneMappingExposure = 2.5;
renderer.outputEncoding = THREE.sRGBEncoding

gui.add(renderer, 'toneMappingExposure').min(0).max(50).step(0.5)

/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

/* window.addEventListener('mousemove', (event) =>
{
    //normalized values
		cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
    //console.log(cursor)
})  */

/**
 * Mouse
 */
const mouse = new THREE.Vector2()


window.addEventListener('mousemove', (event) =>
{
    mouse.x = event.clientX / sizes.width * 2 - 1
    mouse.y = - (event.clientY / sizes.height) * 2 + 1
})


/**
 * Animate
 */
const clock = new THREE.Clock()
//let curvePosition = 0

gui.add(this.three.parameters, 'curvePosition').min(0).max(1).step(0.01)
/* gui.add(this.three.parameters, 'curvePositionTimer').min(0).max(1).step(0.001)
gui.add(this.three.parameters, 'curvePositionStart').min(0).max(1).step(0.001)
gui.add(this.three.parameters, 'curvePositionEnd').min(0).max(1).step(0.001)
gui.add(this.three.parameters, 'curvePositionAnimate') */

/* gui.add(this.three.parameters, 'curveVelocity').min(0).max(5).step(0.1) */

let previousTime = 0
let curveTime = 0
//let curvePositionPoint

/**
 * Move the curve
 */


/**
 * Mousewheel?
 */

  window.addEventListener('wheel', (event) =>
  {
      if(this.page == 'show'){
        this.moveTheCurve(event.deltaY)
      }
  })



/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()
let currentIntersect = null

window.addEventListener('mousemove', (event) =>
{
    //normalized values
		cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

function stopAnimation(){
	this.three.parameters.cameraMove.ready = false
	// console.log('stopAnimation')
}


// document.getElementById( 'dispose' ).addEventListener('click', () => {
//   console.log('click')
// })


/**
 * raycaster click
 */
const loader = this.three.loaders.gltf

  window.addEventListener('click', () => {
  		if(currentIntersect && this.page == 'show' && this.three.parameters.cameraOn)
      {
        // console.log('oggetto selezionato currentIntersect', currentIntersect.object.name)
  			this.page = 'swipe'
  			this.three.scene.name = currentIntersect.object.name

  			for (var i = 0; i < this.three.scene.cameraStep.length; i++) {

  				if( this.three.scene.cameraStep[i].name == currentIntersect.object.name){
  					// console.log('oggetto selezionato ok', currentIntersect.object.name)
  					let nameString = currentIntersect.object.name
  					this.current.section = nameString.replace('Click-', '');

            // this.current.settingsIndex
            // result = this.three.scene.stepList[nn].step.find(x => x.t >= (this.three.parameters.curvePosition - offset) )

            //console.log( '++scene', this.three.scene.stepList.find( x => x.scene = this.current.settingsIndex ) )

            let currentSceneStepList = this.three.scene.stepList.find( x => x.scene === this.current.settingsIndex )
            //let currentSceneStepItem = currentSceneStepList.find( x => x.key === this.three.scene.cameraStep[i].key )

            //console.log( 'step',currentSceneStepList.step.find( x => x.key = this.three.scene.cameraStep[i].key ) );



            /*for(var nn = 0; nn < Object.keys(this.three.scene.stepList).length; nn++){
              if(this.three.scene.stepList[nn].scene === this.current.settingsIndex){
                console.log(this.three.scene.stepList[nn].step.find( x => x.key = this.three.scene.cameraStep[i].key ))
              }
            }*/




            //console.log('current.section', this.current.section, this.three.scene.cameraStep[i].key)

            this.loadData(scene, controls, cameraControls)
            this.current.item = 0

            this.hideElements()

            //cerca se è un 3D
            for(let clickArea in this.three.scene.models.clickArea){
              // console.log(this.three.scene.models.clickArea[clickArea])
              if(this.three.scene.models.clickArea[clickArea].name == this.current.section){
                //console.log('carico il 3d',this.three.scene.models.clickArea[clickArea].file)

                //load model
                //const hiQualityModel = this.three.scene.models.clickArea[clickArea].file
                //console.log('model/' + hiQualityModel + '/' + hiQualityModel + '.gltf')

                  // loader.load(
                  //   'model/' + hiQualityModel + '/' + hiQualityModel + '.gltf',
                  //   function (gltf ) {
                  //     //hiQualityModelMesh = gltf.scene
                  //     gltf.scene.position.set(0,0,0)
                  //     scene.add( gltf.scene )
                  //   },
                  //   function ( xhr ) {
                  //     console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                  //   },
                  //   function ( error ) {
                  //     console.log( 'An error happened' );
                  //   }
                  // );

                // controls.target = new THREE.Vector3(
                //   0,
                //   1.5,
                //   0
                // )
                // cameraControls.position = new THREE.Vector3(
                //   0,
                //   1.5,
                //   7
                // )
                // gsap.to(cameraControls.position, {duration:1,delay:0, z:5} )
                // controls.maxPolarAngle = Math.PI/2;
                // controls.maxAzimuthAngle = Math.PI/2
                // controls.minAzimuthAngle = -Math.PI/2
                // this.three.parameters.cameraOn = false

              }
            }



  					this.updateCurvePosition( this.three.scene.cameraStep[i] )
  				}
  			}

  			//this.updateCurvePosition(step)
  			currentIntersect = null
  			this.setNextFrame()
      }
  })

var target = new THREE.Vector3();

/*
 * ANIMATE TICK
 */
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
		const deltaTime = elapsedTime - previousTime
		previousTime = elapsedTime

//parallax
/*
    if(this.page == 'show' && this.three.parameters.cameraOn && this.mobile === false){
      const parallaxX = cursor.x
      const parallaxY = - cursor.y
      cube3.position.x += (parallaxX - cube3.position.x) * 5 * deltaTime
      cube3.position.y += (parallaxY - cube3.position.y) * 5 * deltaTime
    }
    */

    /*
     * FFWD REW on the curve
     */

     if(this.three.parameters.curvePositionTrigger.forward){
       this.moveTheCurve(20)
     }
     if(this.three.parameters.curvePositionTrigger.back){
       this.moveTheCurve(-20)
     }

		/**
		 * raycaster
		 */
		raycaster.setFromCamera(mouse, this.three.scene.camera)
    const intersects = raycaster.intersectObjects(this.three.scene.objectsToTest, false)

		//current intersect
    if(this.three.parameters.cameraOn ){
    if(intersects.length && this.three.parameters.raycast && this.page == 'show')
    {
        if(!currentIntersect)
        {
            // console.log('mouse enter in ' + intersects[0].object.name)
						// this.view.item.originalMaterial = intersects[0].object.material
						// console.log('OldMaterial ', this.view.item.originalMaterial)
            //
						// intersects[0].object.material = childMaterialHover
            //console.log('intersects[0]',intersects[0])

            //prendi il punto t degli oggetti selezionati.
            //console.log('+++',this.three.scene.cameraStep.find( x => x.name === intersects[0].object.name ).t, this.three.parameters.curvePosition -0.15, this.three.parameters.curvePosition +0.20)

            //se il punto t è vicino alla posizione attuale l'oggetto è selezionabile
            if( this.three.scene.cameraStep.find( x => x.name === intersects[0].object.name ).t > (this.three.parameters.curvePosition -0.15) && this.three.scene.cameraStep.find( x => x.name === intersects[0].object.name ).t < (this.three.parameters.curvePosition + 0.2) ){
              currentIntersect = intersects[0]
              document.getElementsByTagName("BODY")[0].classList.add("pointer-on");
              //console.log('ok');
            }


        }
    }
    else
    {
        if(currentIntersect)
        {
            // console.log('mouse leave ' + currentIntersect.object.name)
						// if(this.view.item.originalMaterial){
						// 	currentIntersect.object.material = this.view.item.originalMaterial
						// 	//console.log('OldMaterial on')
						// }else{
						// 	currentIntersect.object.material = childMaterial
						// }
        }
        document.getElementsByTagName("BODY")[0].classList.remove("pointer-on");
        currentIntersect = null
    }
  }

		if(this.three.parameters.curvePositionNext != null){
				curveTime++

				//direction
				this.three.parameters.curvePositionDirection = 1
				if(this.three.parameters.curvePositionNext < (this.three.parameters.curvePosition + 0.01) ){ // +0.01 per evitare valori doppi.
						this.three.parameters.curvePositionDirection = -1
				}

				//increase curve position point
				if ((this.three.parameters.curvePosition < this.three.parameters.curvePositionNext && this.three.parameters.curvePositionDirection === 1) || (this.three.parameters.curvePosition > this.three.parameters.curvePositionNext && this.three.parameters.curvePositionDirection === -1)) {
					this.three.parameters.curvePosition += ( 0.001 * curveTime * this.three.parameters.curveVelocity * this.three.parameters.curvePositionDirection)
				}else{
					if(this.three.parameters.curvePosition>1){
						this.three.parameters.curvePosition = 1
					}else if(this.three.parameters.curvePosition<0){
						this.three.parameters.curvePosition = 0
					}
					//stop and reset
					curveTime = 0
					this.three.parameters.curvePositionNext = null
					// console.log('stop')
				}

        this.updateLabelPosition()
		}

    /*
     * CURVE POSITION
     */
    if(this.three.parameters.curvePosition >= 1){
      //console.log('this.three.parameters.curvePosition',this.three.parameters.curvePosition)
      this.page = 'intro'
      this.three.parameters.curvePosition = 0
      this.three.parameters.curvePositionNext = null
      this.three.parameters.curvePositionTimer = 0
      this.stopMoveCurvePosition()

      let keys = Object.keys( this.current.settings.Home.Sezioni );
      let currentIndex = keys.indexOf( this.current.settingsIndex.toString() )
      let newIndex = currentIndex + 1


      // let currenturl = window.location.toString();
	    // //Remove anchor from url using the split
      // currenturl = currenturl.split(“#”)[0];

      //console.log(currenturl)

      if(newIndex < keys.length ){
        this.three.parameters.curvePositionSelected = false
        this.introSection(parseInt(keys[newIndex]),true)

        window.history.pushState("", "New Section", "#" + this.current.settings.Home.Sezioni[parseInt(keys[newIndex])].Slug );
        //console.log(keys[newIndex], this.current.settings.Home.Sezioni, keys)
        //console.log( this.current.settings.Home.Sezioni[parseInt(keys[newIndex])].Slug )

      }else{
        this.current.settingsIndex = null
        this.showSection('colophon',true)
        window.history.pushState("object or string", "Colophon", "#colophon");
      }

    }

		/*
		 * move camera & cube
		 */
		if(this.three.scene.cameraPositionSpline.curve && (this.page == 'show' || this.page == 'intro') && this.three.parameters.curvePosition <= 1 && this.three.parameters.curvePosition >= 0){
			this.three.parameters.curvePositionTimer = 0

			// let cameraSplineCurve = this.three.scene.cameraPositionSpline.curve
			// let targetSplineCurve = this.three.scene.cameraPositionSpline.targetCurve
			// let cameraSplineCurvePosition = this.three.parameters.curvePosition

			if(this.three.parameters.cameraMove.start && this.three.parameters.cameraMove.end){
					this.three.scene.cameraPositionSpline.curve = new THREE.CatmullRomCurve3( this.three.parameters.cameraMove.start, this.three.parameters.cameraMove.end)
			}

      //let t = this.three.scene.cameraPositionSpline.curve.getUtoTmapping( this.three.parameters.curvePosition )
			let getPoint = this.three.scene.cameraPositionSpline.curve.getPoint( this.three.parameters.curvePosition )
      let getPoint2 = this.three.scene.cameraPositionSpline.targetCurve.getPoint( this.three.parameters.curvePosition )

      cameraGroup.position.set(getPoint.x,getPoint.y,getPoint.z)
      this.three.scene.camera.position.set(getPoint.x,getPoint.y,getPoint.z)
      targetGroup.position.set(getPoint2.x,getPoint2.y,getPoint2.z)

			//il target guarda la camera
			targetGroup.lookAt(cameraGroup.position)
			//cube3 è il punto di vista e lo trasferisce sul vettore "target"
			cube3.getWorldPosition( target );
			//e la camera guarda "target"
			this.three.scene.camera.lookAt(target)
		}

    // switch camera Render
		if(this.three.parameters.cameraOn){
			renderer.render(scene, this.three.scene.camera)
		}else{
			renderer.render(scene, cameraControls)
		}

    //update controls
		controls.update();

    //Update Stats
		//stats.update();

    renderer.antialias = false;

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}
tick()

		}
	}
})
