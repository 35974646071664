
//Javascript module
var easyBench = (function(g){


	//Private variable
	var LOG = {};


	/**
	* Private part ___________________________________________________________________
	*/


	/**
	 * Compute the fibonacci serie
	 * Courtesy of https://sebhastian.com/fibonacci-recursion-javascript/
	*/
	function fibonacci(n, memo)
	{

		if (typeof(memo)==='undefined') memo = {};

		if (n < 2) {
		  return n;
		}
		if(!memo[n]) {
		  // when the object doesn't have the property of n
		  // store the result of the call inside memo[n]
		  memo[n] = fibonacci(n - 1, memo) + fibonacci(n - 2, memo);
		}
		return memo[n];
	}


	/**
	 * Courtesy of http://stackoverflow.com/questions/1527803/ddg#1527820
	 * Returns a random integer between min (inclusive) and max (inclusive).
	 */
	function getRandomInt(min, max) //Max 9007199254740992
	{

		if (typeof(max)==='undefined') max = 9007199254740992;
		if (typeof(min)==='undefined') min = max/1000;

		  min = Math.ceil(min);
		  max = Math.floor(max);
		  return Math.floor(Math.random() * (max - min + 1)) + min;
	}


	/**
	 * Find the least factor in n by trial division
	*/
	function leastFactor(n)
	{

		if (isNaN(n) || !isFinite(n)) return NaN;

		if (n==0) return 0;
		if (n%1 || n*n<2) return 1;
		if (n%2==0) return 2;
		if (n%3==0) return 3;
		if (n%5==0) return 5;

		var m = Math.sqrt(n);
		for (var i=7;i<=m;i+=30) {
			if (n%i==0)      return i;
			if (n%(i+4)==0)  return i+4;
			if (n%(i+6)==0)  return i+6;
			if (n%(i+10)==0) return i+10;
			if (n%(i+12)==0) return i+12;
			if (n%(i+16)==0) return i+16;
			if (n%(i+22)==0) return i+22;
			if (n%(i+24)==0) return i+24;
		}

		return n;
	}


	/**
	 * Compute the prime factorization of the input integer n
	 * Courtesy of http://javascripter.net/math/primes/factorization.htm
	*/
	function factor(n)
	{

	 if (isNaN(n) || !isFinite(n) || n%1!=0 || n==0) return ''+n;

	 if (n<0) return '-'+factor(-n);

	 var minFactor = leastFactor(n);
	 if (n==minFactor) return ''+n;

	 return minFactor+'*'+factor(n/minFactor);
	}



	/**
	* Public part _________________________________________________________________
	*/


	// Return a public object
	return {

		/**
		* Load configuration objects
		*/
		load: function (debug=false) {

			//Disabling console when debug is disabled (default)
			//Use LOG() instead of console.log() in this script
			LOG = debug ? console.log.bind(console) : function () {};

    	},



		computeProcessingPower: function(){


			// 1 - Fibonacci
			const startFibonacci = performance.now();

				for(var i=0; i<2000; i++){
					let mediumInt = getRandomInt(500, 1200);
					let fibo = fibonacci(mediumInt);
				}

			const durationFibonacci = performance.now() - startFibonacci;
			LOG("Fibonacci took", durationFibonacci);




			// 2 - Factorization
			const startFactorization = performance.now();


			let list = [ //To force constant
					315004697820603, 4045428998915188, 7342870752648343, 6638927672886907, 7048409796651633,
					4261119620536493, 5672291153388157, 2736657304541464, 8760564901681886, 6350839576533929,
					6052888318509480, 2913022763283746, 333878274336941, 2216525149799375, 851872327204402,
					3014875765755632, 7321768168777842, 8914840041904698, 3947685497422467, 3542935789820947
				];


			list.forEach(function(bigInt, index, arr){

				let fact = factor(bigInt);
				//LOG(bigInt, fact);

			});

			/*
			for(var i=0; i<100; i++){
				let bigInt = getRandomInt();
				let fact = factor(bigInt);
				LOG(bigInt, fact);
			}
			*/

			const durationFactorization = performance.now() - startFactorization;
			LOG("Factorization took", durationFactorization);

			let elapsed = Math.floor(durationFibonacci + durationFactorization);
			let score = Number((1000/elapsed).toFixed(2));

			return [elapsed,score];
		}
  }

})(window);

export default easyBench
